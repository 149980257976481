import { LANGUAGES } from 'config/constants/languages';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export const useLanguageChangeEventListener = () => {
  const { i18n } = useTranslation();

  useEffect(() => {
    const init = () => {
      const selectedLanguage = LANGUAGES.find((item) => item.code === i18n.language);
      if (selectedLanguage?.ISO) {
        document.querySelector('html').lang = selectedLanguage.ISO;
      }
    };

    init();
  }, [i18n.language]);
};
