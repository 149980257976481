import useActionQueryListener from 'hooks/useActionQueryListener';
import useSentryUser from 'hooks/useSentryUser';
import { usePollSystemData } from 'state/system/hooks';
import { usePollUserBalances, usePollUserProfile, useUpdateUserToken } from 'state/profile/hooks';
import { usePollPendingTransaction } from 'state/transaction/hooks';
import { useUpdateScreenHeight } from 'hooks/useUpdateScreenHeight';
import useAnalyticsUser from 'hooks/useAnalyticsUser';
import { useLanguageChangeEventListener } from 'hooks/useLanguageChangeEventListener';
import { useActivatedBonusesUpdater } from 'state/bonus/hooks';

const GlobalHooks = () => {
  usePollSystemData();
  usePollUserProfile();
  useUpdateUserToken();
  useSentryUser();
  useAnalyticsUser();
  usePollUserBalances();
  useActionQueryListener();
  usePollPendingTransaction();
  useLanguageChangeEventListener();
  useUpdateScreenHeight();
  useActivatedBonusesUpdater();
  return null;
};

export default GlobalHooks;
