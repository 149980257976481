import { ChainIdEnum } from 'config/constants/network';
import { useEffect, useRef } from 'react';
import { useAppSelector } from 'state';
import { useAuth } from 'state/auth/hooks';
import { useAnalytics } from './useAnalytics';

function useAnalyticsUser() {
  const { username, uid, loginBy, isSigned } = useAuth();
  const info = useAppSelector((state) => state.profile.info);
  const wallet = useAppSelector((state) => state.auth.wallet);
  const { updateUserId, updateUserType, recordEvent } = useAnalytics();
  const selectToken = useAppSelector((state) => state.app.selectToken);
  const refToken = useRef(selectToken);

  useEffect(() => {
    if (username && uid) {
      updateUserId(uid.toString());
      updateUserType('type', loginBy);
    } else {
      updateUserId(null);
    }
  }, [username, uid, updateUserId, updateUserType, loginBy]);

  useEffect(() => {
    if (isSigned && refToken.current !== selectToken) {
      updateUserType('coin', `${ChainIdEnum[selectToken.network]} | ${selectToken.token}`);
      recordEvent('select_balance', {
        [ChainIdEnum[selectToken.network]]: selectToken.token,
      });
      refToken.current = selectToken;
    }
  }, [isSigned, recordEvent, selectToken, updateUserType]);

  // useEffect(() => {
  //   if (info) {
  //     updateUserType('address', info.address.toString());
  //   }
  // }, [info, updateUserType]);

  useEffect(() => {
    if (wallet?.trackingName) updateUserType('wallet', wallet?.trackingName);
  }, [updateUserType, wallet]);
}

export default useAnalyticsUser;
